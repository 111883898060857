import {
  Grid,
  Image,
  Text,
  Rating,
  Divider,
  Button,
  Box,
  Flex,
} from "@mantine/core";
import { MdHideImage } from "react-icons/md";
import { useDisclosure } from "@mantine/hooks";
import { HotelDetail } from "./hotelDetail.component";
import { useState } from "react";

const HotelCard = ({
  data,
  tokenApi,
  travelerId,
  nextHotel,
  classes,
  GlobalCard,
  isSm,
  utils,
  fetchHotelDetailData,
  isAid,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [dataDrawer, setDataDrawer] = useState({
    stars: 0,
    price: 0,
    optionId: "",
  });
  const [selectedHotel, setSelectedHotel] = useState({});
  const [errorImages, setErrorImages] = useState([]);

  const handleError = (index) => {
    const newImages = [...errorImages];
    newImages[index] = false;
    setErrorImages(newImages);
  };

  const handleOpenDrawer = (hotel) => {
    open();
    setSelectedHotel(hotel);
    setDataDrawer({
      price: hotel.fares.totalAmount,
      stars: hotel.numberStars.split(" ")[0],
      optionId: hotel.id,
    });
  };

  const hotelDetailProps = {
    nextHotel,
    opened,
    close,
    tokenApi,
    dataProps: dataDrawer,
    setDataProps: setDataDrawer,
    travelerId,
    classes,
    utils,
    fetchHotelDetailData,
    selectedHotel,
    isAid,
  };

  return (
    <>
      {data?.map((hotel, index) => (
        <GlobalCard key={index}>
          <Grid h={isSm ? "auto" : "224px"} columns={13}>
            <Grid.Col md={5} lg="auto">
              {errorImages[index] !== false ? (
                <Image
                  radius="md"
                  height={208}
                  src={hotel.image}
                  onError={() => handleError(index)}
                />
              ) : (
                <Flex align="center" justify="center" h="100%">
                  <MdHideImage size="2rem" color="#004236" />
                </Flex>
              )}
            </Grid.Col>
            <Grid.Col md={4} lg="auto" sx={{ alignSelf: "center" }}>
              <Text size="lg" fw={700} c="#004236">
                {hotel.nameHotel}
              </Text>
              <Rating
                defaultValue={hotel.numberStars.split(" ")[0]}
                readOnly
                my={10}
              />
              <Text size="sm" my={10}>
                {hotel.description}
              </Text>
              <Text size="sm" my={10}>
                {hotel.serviceConditions}
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Divider orientation="vertical" h="100%" />
            </Grid.Col>
            <Grid.Col xs={13} md={3} sx={{ alignSelf: "center" }}>
              <Box>
                <Text size="sm" fw={700} align="center" c="dimmed">
                  Valor total
                </Text>
                <Text size="xl" fw={700} c="#004236" align="center">
                  {utils.formatter.format(hotel.fares.totalAmount)}
                </Text>
                <Text size="sm" fw={700} align="center">
                  {data.lowerRate === hotel.fares.totalAmount &&
                    "Tarifa más baja"}
                </Text>
                <Button
                  mb={15}
                  fullWidth
                  className={classes.button}
                  data-testid="select-hotel-button"
                  onClick={() => nextHotel(hotel)}
                >
                  Seleccionar
                </Button>
                <Button
                  onClick={() => handleOpenDrawer(hotel)}
                  variant="outline"
                  fullWidth
                  data-testid="show-details-button"
                  className={classes.buttonOutline}
                >
                  Ver Detalle
                </Button>
              </Box>
            </Grid.Col>
          </Grid>
        </GlobalCard>
      ))}
      <HotelDetail {...hotelDetailProps} />
    </>
  );
};

export default HotelCard;

import {
  Grid,
  Image,
  Text,
  Rating,
  Button,
  Drawer,
  LoadingOverlay,
  Flex,
} from "@mantine/core";
import { MdHideImage } from "react-icons/md";
import { useEffect, useState } from "react";

export const HotelDetail = ({
  nextHotel,
  opened,
  close,
  tokenApi,
  dataProps,
  setDataProps,
  travelerId,
  classes,
  utils,
  fetchHotelDetailData,
  selectedHotel,
  isAid,
}) => {
  const [dataDrawer, setDataDrawer] = useState({
    hotelDetail: null,
  });
  const [loading, setLoading] = useState(false);
  const [errorImage, setErrorImage] = useState(false);

  useEffect(() => {
    if (tokenApi && dataProps.optionId) {
      fetchHotelDetailData(
        setDataDrawer,
        tokenApi,
        dataProps.optionId,
        setLoading,
        travelerId,
        isAid
      );
    }

    return () => {
      setDataDrawer({ hotelDetail: null });
      setErrorImage(false);
    };
  }, [tokenApi, dataProps.optionId]);

  const handleClose = () => {
    close();
    setDataProps({
      stars: 0,
      price: 0,
      optionId: "",
    });
  };

  const handleClick = () => {
    nextHotel(selectedHotel);
    close();
  };

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      overlayProps={{ opacity: 0.5, blur: 4 }}
      classNames={{
        overlay: classes.drawerOverlay,
        inner: classes.drawerInner,
      }}
      zIndex={300}
    >
      <LoadingOverlay visible={loading} loaderProps={{ color: "#cbd300" }} />
      {!loading && (
        <>
          <Grid>
            <Grid.Col span="auto" my={15}>
              <Text size="lg" fw={700} c="#004236">
                {dataDrawer.hotelDetail?.nameHotel}
              </Text>
            </Grid.Col>
            <Grid.Col span="content" my={15}>
              <Rating defaultValue={dataProps.stars} readOnly />
            </Grid.Col>
          </Grid>
          {errorImage ? (
            <Image
              radius="md"
              src={dataDrawer.hotelDetail?.imagesRoom[0].url}
              onError={() => setErrorImage(true)}
            />
          ) : (
            <Flex align="center" justify="center" h="100%">
              <MdHideImage size="2rem" color="#004236" />
            </Flex>
          )}
          <Text size="sm" my={15}>
            {dataDrawer.hotelDetail?.penalty.value}
          </Text>
          <Text size="md" fw={700} c="#004236" my={10}>
            Ubicación
          </Text>
          <Text size="sm" mx={10}>
            {dataDrawer.hotelDetail?.address}
          </Text>
          <Text size="md" fw={700} c="#004236" my={10}>
            Descripción
          </Text>
          <Text size="sm" mx={10}>
            {dataDrawer.hotelDetail?.description}
          </Text>
          <Text size="md" fw={700} c="#004236" my={10}>
            Servicios
          </Text>
          <Text size="sm" mx={10}>
            {dataDrawer.hotelDetail?.servicesHotel[0].value}
          </Text>

          <Text size="lg" fw={700} c="#004236" my={10} ta="center">
            Total: {utils.formatter.format(dataProps.price)}
          </Text>
          <Button
            fullWidth
            className={classes.button}
            onClick={handleClick}
            data-testid="action-button"
          >
            Seleccionar
          </Button>
        </>
      )}
    </Drawer>
  );
};

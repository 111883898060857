import { useState, useEffect } from "react";
import {
  useStyles,
  useWindowSize,
  ModalComponent,
  StepperComponent,
  WCInputFilter,
  WCInlineInputFilter,
  GlobalCard,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { FaCalendarDays, FaPlus } from "react-icons/fa6";
import { LuChevronDown } from "react-icons/lu";
import { FaHotel } from "react-icons/fa";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  formatter,
  filterEmpty,
  lodash,
  fetchHotelData,
  getAccessTokenApi,
  fetchCitiesData,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorUpdateData,
  moment,
  parseDateWihtFormat,
  getArrayByName,
  valuePerNight,
  fetchHotelReservationDetail,
  fetchHotelDetailData,
  fetchHotelDetail,
} from "@mfe/ts-common-ave-utilitaries";
import { CardHotelComponent } from "../components/cardHotel.component";

export default function BookHotels({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookHotelsComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookHotelsComponent = ({ organization }) => {
  const { classes } = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const isSm = useWindowSize("md");

  const [tokenApi, setTokenApi] = useState("");
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [priceValue, setPriceValue] = useState([]);
  const [travelIndex, setTravelIndex] = useState(null);
  const [finalHotel, setFinalHotel] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [loadingWindow, setLoadingWindow] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const [data, setData] = useState({
    travelLiquidatorData: {
      ViajeTrayecto: [],
      Multiviaje: "",
      TipoViajeAlias: "",
      TipoViajeId: "",
      Id: "",
      CodigoEmpleadoViajero: "",
    },
    filterHotels: [],
    hotelList: [],
    travelDestinations: [],
    higherRate: "",
    lowerRate: "",
  });

  const formTopFilter = useForm({
    initialValues: {
      destinationFilter: "",
      destinationSearchFilter: "",
      datesFilter: [],
    },
  });

  const form = useForm({
    initialValues: {
      nameHotel: "",
      stars: [],
    },
  });

  const formSlider = useForm({
    initialValues: {
      prices: [],
    },
  });

  const filterMapped = {
    priceMin: priceValue[0],
    priceMax: priceValue[1],
    nameHotel: form.values.nameHotel,
    starsMin: form.values.stars[0],
  };
  const nextHotelMap: any = data?.travelLiquidatorData?.ViajeTrayecto?.filter(
    (trip) =>
      trip?.ServicioBpo?.some((service) => service.AliasServicio === "HOTEL")
  );

  const updateOriginalList = (original, modified) => {
    modified.forEach((modificado) => {
      const indice = original.findIndex(
        (original) => original.Id === modificado.Id
      );

      if (indice !== -1) {
        original[indice] = modificado;
      }
    });

    return original;
  };

  const nextHotel = async (item) => {
    const initialDate = hasSearched
      ? formTopFilter.values.datesFilter[0]
      : nextHotelMap[travelIndex]?.FechaInicio;
    const finalDate = hasSearched
      ? formTopFilter.values.datesFilter[1]
      : nextHotelMap[travelIndex]?.FechaFin;

    let hotelAddress = "";

    if (!!Object.keys(item).length) {
      try {
        setLoadingWindow(true);
        await fetchHotelReservationDetail(tokenApi, {
          hotel: item,
          travel: nextHotelMap[travelIndex],
          price: data?.travelLiquidatorData,
          higherRate: data?.higherRate,
          lowerRate: data?.lowerRate,
          initialDate,
          finalDate,
        });

        const responseDetail = await fetchHotelDetail(
          tokenApi,
          item?.id,
          data?.travelLiquidatorData?.CodigoEmpleadoViajero,
          data?.travelLiquidatorData?.TipoViajeAlias === "TVAMBULATORIOS"
        );

        if (responseDetail?.status === 200) {
          hotelAddress = responseDetail?.data?.address;
        }
      } catch {
      } finally {
        setLoadingWindow(false);
      }
    }

    const newHotelMap = [...nextHotelMap];
    const validateEmpty = !Object.keys(item).length;

    const hotelIndex = newHotelMap[travelIndex].ServicioBpo.findIndex(
      (servIndex) => servIndex.AliasServicio === "HOTEL"
    );

    validateEmpty
      ? (newHotelMap[travelIndex].ServicioBpo[hotelIndex] = filterEmpty({
          ...newHotelMap[travelIndex].ServicioBpo[hotelIndex],
        }))
      : (newHotelMap[travelIndex].ServicioBpo[hotelIndex] = {
          ...newHotelMap[travelIndex].ServicioBpo[hotelIndex],
          Valor: valuePerNight(
            item?.fares?.totalAmount,
            initialDate,
            finalDate
          ),
          nombreServicio: item?.nameHotel,
          descripcionServicio: JSON.stringify({
            ...item,
            isCheapest: item?.fares?.totalAmount === data?.lowerRate,
            fechaInicio: initialDate,
            fechaFin: finalDate,
            origin: nextHotelMap[travelIndex]?.nombreCiudadOrigen,
            destination: nextHotelMap[travelIndex]?.nombreCiudadDestino,
            address: hotelAddress,
          }),
          estrellasHotel: item?.numberStars,
          imagenServicio: item?.image,
        });

    setSelectedHotel(newHotelMap);
    setHasSearched(false);

    const remainingIndexes = newHotelMap
      .slice(travelIndex + 1)
      .findIndex((servIndex) =>
        servIndex.ServicioBpo?.some(
          (service) => service.AliasServicio === "HOTEL"
        )
      );

    if (remainingIndexes !== -1) {
      setTravelIndex(travelIndex + 1);
    } else {
      data?.travelLiquidatorData?.TipoViajeAlias === "TVINTERNCL" && open();
      setFinalHotel(true);
    }
    form.reset();
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    formSlider.setFieldValue("prices", [
      Number(data?.lowerRate),
      Number(data?.higherRate),
    ]);
    formTopFilter.setValues({
      datesFilter: [
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[0]
            : nextHotelMap[travelIndex]?.FechaInicio
        ).toDate(),
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[1]
            : nextHotelMap[travelIndex]?.FechaFin
        ).toDate(),
      ],
      destinationFilter: hasSearched
        ? formTopFilter.values.destinationFilter
        : [
            nextHotelMap[travelIndex]?.CodigoIatadestino,
            nextHotelMap[travelIndex]?.ciudadDestinoGrupo,
            nextHotelMap[travelIndex]?.nombreCiudadDestino,
          ].join("/"),
    });
  }, [
    data?.lowerRate,
    data?.higherRate,
    nextHotelMap[travelIndex]?.CodigoIatadestino,
    hasSearched,
  ]);

  useEffect(() => {
    if (nextHotelMap.length) {
      const indice = nextHotelMap
        .map((item) => item.ServicioBpo)
        .findIndex((service) =>
          service.some((s) => s.AliasServicio === "HOTEL")
        );

      setTravelIndex(indice);
    }
  }, [data?.travelLiquidatorData]);

  useEffect(() => {
    if (travelIndex !== null) {
      fetchCitiesData(
        setData,
        tokenApi,
        data?.travelLiquidatorData?.TipoViajeId,
        parseDateWihtFormat(nextHotelMap[travelIndex]?.FechaFin, "YYYY-MM-DD"),
        false,
        () => {},
        () => {},
        () => {},
        false
      );
      if (pageNumber !== 1) {
        setPageNumber(1);
      }
      fetchHotelData(
        setData,
        setLoading,
        setError,
        tokenApi,
        1,
        nextHotelMap[travelIndex],
        filterEmpty(filterMapped),
        formTopFilter.values,
        false,
        data?.travelLiquidatorData
      );
    }
  }, [form.values, priceValue, travelIndex]);

  useEffect(() => {
    if (pageNumber > 1) {
      fetchHotelData(
        setData,
        setLoading,
        setError,
        tokenApi,
        pageNumber,
        nextHotelMap[travelIndex],
        filterEmpty(filterMapped),
        formTopFilter.values,
        false,
        data?.travelLiquidatorData
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    if (finalHotel && opened === false) {
      fetchTraveLiquidatorUpdateData(
        tokenApi,
        {
          ...data?.travelLiquidatorData,
          ViajeTrayecto: updateOriginalList(
            data?.travelLiquidatorData?.ViajeTrayecto,
            selectedHotel
          ),
        },
        setLoadingWindow
      );
    }
  }, [finalHotel, opened]);

  const changeCityHotel = () => {
    setHasSearched(true);
    setError("");
    form.reset();
    formSlider.reset();
    const filterMapped = {
      priceMin: 0,
      priceMax: 0,
      nameHotel: form.values.nameHotel,
      starsMin: form.values.stars[0],
    };
    fetchHotelData(
      setData,
      setLoading,
      setError,
      tokenApi,
      pageNumber,
      nextHotelMap[travelIndex],
      filterEmpty(filterMapped),
      formTopFilter.values,
      true,
      data?.travelLiquidatorData
    );
  };

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const topFilterData = [
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Destino",
      value: "destinationFilter",
      icon: <FaHotel size="1.2rem" color="#004236" />,
      form: formTopFilter.getInputProps("destinationFilter"),
      data: lodash(data?.travelDestinations).sortBy("label").value(),
      searchValue: formTopFilter.values.destinationSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("destinationSearchFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "dateInput",
      placeHolder: "Seleccionar Fechas",
      value: "datesFilter",
      icon: <FaCalendarDays size="1.2rem" color="#004236" />,
      form: formTopFilter.getInputProps("datesFilter"),
    },
    {
      lg: 2,
      md: "content",
      inputType: "button",
      title: "Buscar",
      onClick: changeCityHotel,
    },
  ];

  const rightFilterData = [
    {
      title: "Precio",
      inputType: "slider",
      value: "prices",
      form: formSlider.getInputProps("prices"),
      valueMin: Number(data?.lowerRate),
      valueMax: Number(data?.higherRate),
      onChangeEnd: setPriceValue,
      textMin: formatter.format(formSlider.values.prices[0]),
      textMax: formatter.format(formSlider.values.prices[1]),
    },
    {
      title: "Nombre del Hotel",
      inputType: "select",
      placeHolder: "Seleccione el hotel",
      value: "nameHotel",
      data: getArrayByName(data?.filterHotels, "hotel") || [],
      form: form.getInputProps("nameHotel"),
      rightSection:
        form.values.nameHotel.length > 0 ? (
          <FaPlus
            style={{
              transform: "rotate(45deg)",
              cursor: "pointer",
            }}
            onClick={() => form.setFieldValue("nameHotel", "")}
          />
        ) : null,
    },
    {
      title: "Estrellas",
      inputType: "checkbox",
      data:
        getArrayByName(data?.filterHotels, "stars").map((star) => ({
          value: star.value[0],
          label: star.label,
        })) || [],
      label: "stars",
      value: "stars",
      form: form.getInputProps("stars"),
    },
  ];

  const cityValidation = hasSearched
    ? formTopFilter.values.destinationFilter.split("/")[2]
    : nextHotelMap[travelIndex]?.nombreCiudadDestino;

  const buttonDataAccordion = [
    {
      title: "Trayecto sin Hotel",
      inputType: "button",
      outline: true,
      onClick: () => nextHotel({}),
    },
  ];

  const utils = { formatter };

  const cardHotelComponentProps = {
    data,
    tokenApi,
    loadMore,
    nextHotel,
    classes,
    isSm,
    loading,
    error,
    opened,
    close,
    utils,
    loadingWindow,
    cityValidation,
    rightFilterData,
    topFilterData,
    buttonDataAccordion,
    fetchHotelDetailData,
    ModalComponent,
    StepperComponent,
    WCInputFilter,
    WCInlineInputFilter,
    GlobalCard,
    travelIndex,
    nextHotelMap,
    isAid: data?.travelLiquidatorData?.TipoViajeAlias === "TVAMBULATORIOS",
  };
  return isAuthenticated ? (
    <CardHotelComponent {...cardHotelComponentProps} />
  ) : null;
};

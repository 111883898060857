import React from "react";
import {
  Grid,
  Box,
  Center,
  Button,
  Text,
  Flex,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import HotelCard from "./hotelCard.component";
import { AiFillWarning } from "react-icons/ai";
import { capitalize } from "lodash";

export const CardHotelComponent = ({
  data,
  tokenApi,
  loadMore,
  nextHotel,
  classes,
  isSm,
  loading,
  error,
  opened,
  close,
  utils,
  loadingWindow,
  cityValidation,
  rightFilterData,
  topFilterData,
  buttonDataAccordion,
  fetchHotelDetailData,
  ModalComponent,
  StepperComponent,
  WCInputFilter,
  WCInlineInputFilter,
  GlobalCard,
  travelIndex,
  nextHotelMap,
  isAid,
}) => {
  const travelerId = data?.travelLiquidatorData?.CodigoEmpleadoViajero;

  const hotelCardProps = {
    data: data?.hotelList,
    tokenApi,
    travelerId,
    nextHotel,
    classes,
    GlobalCard,
    isSm,
    utils,
    fetchHotelDetailData,
    isAid,
  };

  if (loadingWindow) {
    return (
      <LoadingOverlay
        visible={loadingWindow}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <ModalComponent
        opened={opened}
        onClose={close}
        title="Alerta"
        closeOnClickOutside={false}
      >
        <Flex direction="column" gap={12}>
          <Text>
            Su reserva de hotel será gestionada por un asesor de la agencia de
            viajes.
          </Text>
          <Text>
            Las tarifas presentadas están sujetas a disponibilidad, cambios y
            algunas son de compra inmediata por lo que existe la posibilidad que
            ya no estén disponibles al momento de hacer la reserva.
          </Text>
          <Text fw={700} color="#004236">
            Algunos hoteles generan cobro de impuestos que se recaudan en el
            destino y deben ser cancelados por el viajero y legalizados
            posteriormente.
          </Text>
          <Button
            sx={{
              alignSelf: "end",
            }}
            data-testid="close-modal"
            className={classes.button}
            onClick={() => {
              close();
            }}
          >
            Continuar
          </Button>
        </Flex>
      </ModalComponent>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <StepperComponent servicesData={data?.travelLiquidatorData} />
      </Box>

      {loading ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
                mih={isSm ? "auto" : "208px"}
              >
                <Text size="md" fw={700}>
                  Cargando hoteles disponibles en el Portafolio de Acuerdos
                  Tarifarios...
                </Text>
                <Loader variant="dots" color="#cbd300" />
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : error.length > 0 ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap={12}
                justify="center"
                align="center"
                direction="column"
                sx={{ height: isSm ? "auto" : "224px" }}
              >
                <Text fw={700} color="#004236" ta="center">
                  {`${capitalize(error)} para la ciudad de ${cityValidation}.`}
                </Text>
                <Button
                  className={classes.buttonOutline}
                  data-testid="skip-action-button"
                  onClick={() => nextHotel({})}
                >
                  Trayecto sin Hotel
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <WCInlineInputFilter inputs={topFilterData} scrollY="157" />
          <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
            <Grid.Col xs={12} md={3} orderSm={1} orderMd={2} orderLg={2}>
              <Box
                sx={{
                  position: "sticky",
                  top: 85,
                }}
              >
                <GlobalCard
                  styles={{
                    padding: "1.25rem .5rem",
                  }}
                >
                  <WCInputFilter
                    inputs={rightFilterData}
                    buttons={buttonDataAccordion}
                  />
                </GlobalCard>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} md={9} orderSm={2} orderMd={1} orderLg={1}>
              <GlobalCard>
                <Grid justify="center" align="center">
                  <Grid.Col span="content">
                    <AiFillWarning
                      size="2rem"
                      style={{
                        color: "#004236",
                        marginRight: "0.5rem",
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span="auto">
                    La reserva de hotel está sujeta a disponibilidad y su
                    conformación se notificará a través de correo electrónico
                    por un representante de la agencia de viajes.
                  </Grid.Col>
                </Grid>
              </GlobalCard>
              <Text size="xl" role="heading" fw={700} color="#004236">
                {`Hoteles disponibles para el viaje de ${nextHotelMap[travelIndex]?.nombreCiudadOrigen} - ${cityValidation}`}
              </Text>
              <HotelCard {...hotelCardProps} />

              <Center h={100}>
                <Box>
                  <Button className={classes.buttonOutline} onClick={loadMore}>
                    Cargar más
                  </Button>
                </Box>
              </Center>
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};
